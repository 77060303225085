<template>
  <div
    :class="autocompleteClass"
    ref="target"
    v-resize="onResize"
  >
    <vz-input
      ref="inputControl"
      v-model="inputValue"
      :label="label"
      :label-public-style="labelPublicStyle"
      :disabled="disabled"
      :max-length="maxLength"
      :required="props.required"
      :small="small"
      :big="big"
      :large="large"
      :icon-name="iconName"
      :icon-url="iconUrl"
      :icon-size="iconSize"
      :icon-color="iconColor"
      :icon-variant="iconVariant"
      :rules="props.rules"
      :placeholder="placeholder"
      :clearable="clearable"
      :error-block-style="errorBlockStyle"
      :model-modifiers="modelModifiers"
      @blur="$breakpoints?.isXS400 ? '' : onBlur($event)"
      @focus="onFocus"
      @input="onNativeInput"
      @click.prevent="onClick"
      @dblclick="onDblClick"
      @paste="onPaste"
      @keydown="onKeydown"
      @clear="onChange(null)"
    />

    <client-only>
      <teleport to="body">
        <transition name="fade">
          <div
            v-if="listOpen" ref="list" class="vz-new-autocomplete-list vz-scroll"
            :class="`vz-new-autocomplete-list-${instance.uid}`"
            :data-open="listOpen || undefined"
            v-scroll="calculateListPosition"
          >

            <div
              v-if="$breakpoints?.isXS400"
              class="autocomplete-close-select"
              @click="isOpen = false"
            >
              ×
            </div>
            <vz-input
              ref="inputControl2"
              class="autocomplete-alternative-position"
              v-show="$breakpoints?.isXS400"
              v-model="inputValue"
              :label="label || ' '"
              :label-public-style="true"
              :disabled="disabled"
              :max-length="maxLength"
              :required="props.required"
              :big="true"
              :icon-name="iconName"
              :icon-size="iconSize"
              :icon-color="iconColor"
              :icon-variant="iconVariant"
              :rules="props.rules"
              :placeholder="placeholder"
              :clearable="clearable"
              :error-block-style="errorBlockStyle"
              :model-modifiers="modelModifiers"
              @blur="onBlur"
              @focus="onFocus"
              @input="onNativeInput"
              @click.prevent="onClick"
              @dblclick="onDblClick"
              @paste="onPaste"
              @keydown="onKeydown"
              @clear="onChange(null)"
            />

            <div
              v-for="(item, index) of itemList"
              :key="index"
              class="vz-new-autocomplete-list-item"
              :class="{active: isActive(item, index)}"
              @click="onChange(item)"
              @mousedown.prevent
            >
              <slot name="item-title" v-bind="item">
                <div class="vz-new-autocomplete-list-item-title" v-html="valueTmpl(item)"/>
              </slot>
              <div class="vz-new-autocomplete-list-item-description" v-html="description(item)"/>
            </div>

            <div v-if="showNoDataText" class="vz-new-autocomplete-list-item active">
              <slot name="no-data">
                <div class="vz-new-autocomplete-list-item-title color-light" v-html="noDataText"/>
                <div class="vz-new-autocomplete-list-item-description" v-text="'Для поиска записей введите текст'"/>
              </slot>
            </div>
          </div>
        </transition>
      </teleport>
    </client-only>
    <slot/>
    <vz-invalid-message v-if="isShowError"/>
  </div>
</template>

<script setup lang="ts">
import {PropType} from "vue"
import {AutocompleteItem} from "~/types/types"
import {useProps} from '~/common/ui-kit-common';
import {useIconProps} from "~/common/ui-kit-icon";
import {useDebounceFn, onClickOutside} from '@vueuse/core'
import $breakpoints from "~/composables/breakpoints";
import VzInput from "~/components/ui-kit/vz-input.vue";
import VzInvalidMessage from "~/components/ui-kit/vz-invalid-message.vue";

const emit = defineEmits([
  'change',
  'search',
  'blur',
  'focus',
  'input',
  'click',
  'dblclick',
  'paste',
  'keydown',
  'update:modelValue',
])

const props = defineProps({
  ...useProps,
  ...useIconProps,
  valueKey: {
    type: String,
    default: '{id}'
  },
  valueTemplate: {
    type: String,
    default: '{title}'
  },
  titleTemplate: {
    type: String,
    default: ''
  },
  descriptionTemplate: {
    type: String,
    default: '{description}'
  },
  noDataText: {
    type: String,
    default: '<strong>Записей не найдено</strong>'
  },
  hideNoDataText: {
    type: Boolean,
    default: false
  },
  customDescriptionTemplate: {
    type: Boolean,
    default: false
  },
  clearValueAfterBlur: {
    type: Boolean,
    default: true
  },
  multiple: {
    type: Boolean,
    default: false
  },
  clearable: {
    type: Boolean,
    default: false
  },
  useSpinner: {
    type: Boolean,
    default: false
  },
  searchable: {
    type: Boolean,
    default: true
  },
  errorBlockStyle: {
    type: Boolean,
    default: false
  },
  items: {
    type: Array as PropType<AutocompleteItem[]>,
    default: []
  },
  modelValue: {
    type: String,
    default: ''
  },
  modelModifiers: {
    default: () => ({trim: false})
  }
})
const isShowError = ref(false)
const list = ref<HTMLDivElement | null>(null)
const inputControl = ref<any>(null)
const inputControl2 = ref<any>(null)
const isOpen = ref(false)
const isSearch = ref(false)
const target = ref(null)
const {dispatchFormEvent} = useValidateble()
const instance = getCurrentInstance()
const debouncedSearch = useDebounceFn((val: string | number) => {
  emit('search', val)
}, 300, {maxWait: 3000})
const currentIndex = ref(-1)
let inputValue = ref('')
let selectedItem = reactive<AutocompleteItem>({})

defineExpose({
  isShowError,
  checkError,
  inputControl,
  inputValue,
  close
})

const uid = computed(() => {
  return instance?.uid
})

onMounted((): void => {
  if (props.clearValueAfterBlur) {
    selectedItem = findItem.value as any

    if (selectedItem) {
      inputValue.value = title(selectedItem)
    }
    return
  }
  inputValue.value = props.modelValue
})

watch(() => props.modelValue, (n, o): void => {
  if (n !== o) {
    isSearch.value = false
    if (props.clearValueAfterBlur) {
      inputValue.value = ''

      if (findItem.value) {
        inputValue.value = title(findItem.value)
        selectedItem = findItem.value
      }
    } else {
      inputValue.value = n
    }
  }
}, {deep: true, flush: 'post'})

watch(() => props.items, () => {
  if (inputValue.value === '' && !isOpen.value) {
    if (findItem.value) {
      inputValue.value = title(findItem.value)
      selectedItem = findItem.value
    }
  } else if (isOpen.value) {
    moveSelection()
  }
})

watch(() => isOpen.value, (val) => {
  if (val && $breakpoints?.isXS400) {
    document.body.style.overflowY = 'hidden'
    return;
  }

  document.body.style.overflowY = null as any
})

const autocompleteClass = computed(() => {
  return {
    'vz-new-autocomplete': props.searchable,
    open: listOpen.value,
    spinner: props.useSpinner,
    disabled: props.disabled
  }
})

const vzInputControl = computed((): HTMLInputElement => {
  return inputControl.value?.$refs?.inputControl || {} as HTMLInputElement
})

const vzInputControl2 = computed((): HTMLInputElement => {
  return inputControl2.value?.$refs?.inputControl || {} as HTMLInputElement
})

async function checkError(silent: boolean = false): Promise<boolean> {
  return await inputControl.value.checkError(silent)
}

const findItem = computed((): AutocompleteItem | undefined => props.items.find(i => i[props.valueKey] === props.modelValue))
const itemList = computed((): AutocompleteItem[] => {
  return props.items
})

const hasItems = computed((): boolean => {
  return props.items.length > 0
})

const showNoDataText = computed(() => {
  if (props.hideNoDataText) {
    return false
  }
  return !!props.modelValue && !hasItems.value
})

const listOpen = computed(() => {
  return isOpen.value && (showNoDataText.value || hasItems.value)
})

async function onBlur(event: InputEvent) {
  if (props.clearValueAfterBlur) {
    inputValue.value = ''
    isSearch.value = false
    if (selectedItem) {
      inputValue.value = title(selectedItem)
    }
  } else {
    await emit('update:modelValue', (event.target as HTMLInputElement).value)
  }
  emit('blur', event)
  nextTick(async () => {
    isOpen.value = false
    dispatchFormEvent(inputControl.value?.inputControl)
  })
}

function onChange(item: AutocompleteItem): void {
  if (!props.clearable && !item) {
    debouncedSearch(props.modelValue)
    setTimeout(() => {
      if (!!vzInputControl.value?.blur) {
        vzInputControl.value.blur()
      }
    }, 30)
    return
  }

  selectedItem = item
  if (props.clearValueAfterBlur) {
    inputValue.value = title(item)
  } else {
    inputValue.value = item[props.valueKey] as string
  }
  isOpen.value = false
  emit('update:modelValue', item?.[props.valueKey]);
  emit('change', item?.[props.valueKey], item);
  nextTick(async () => {
    setTimeout(() => {
      if (!!vzInputControl.value?.blur) {
        vzInputControl.value.blur()
      }
    }, 30)
    dispatchFormEvent(inputControl.value?.inputControl)
  })
}

function onFocus(event: Event): void {
  isOpen.value = true;

  if ($breakpoints?.isXS400) {
    nextTick(() => {
      vzInputControl2.value?.focus()
      inputControl2.value?.inputControl?.select()
    })
  } else if ($breakpoints?.isXS) {
    inputControl.value?.$el.scrollIntoView({block: 'start', behavior: 'smooth'})
  } else {
    inputControl.value?.inputControl?.select()
    moveSelection()
  }

  const index = props.items.findIndex(i => i[props.valueKey] === selectedItem?.[props.valueKey])
  currentIndex.value = index === -1 ? 0 : index
  isSearch.value = true
  emit('focus', event)
}

function onNativeInput(e: InputEvent): void {
  if (!e.isTrusted) {
    return
  }

  const val = (e.target as any).value
  currentIndex.value = 0
  if (!props.clearValueAfterBlur) {
    emit('update:modelValue', val)
  }
  debouncedSearch(val)
}

function onClick(event: MouseEvent): void {
  if (props.disabled) {
    return
  }
  isOpen.value = true
  emit('click', event)
}

function onDblClick(event: MouseEvent): void {
  emit('dblclick', event)
}

function onKeydown(event: KeyboardEvent): void {
  onClickESC(event)
  emit('keydown', event)
}

function onPaste(event: ClipboardEvent): void {
  emit('paste', event)
}

function title(item: AutocompleteItem): string {
  if (item) {
    let template = props.valueTemplate
    templateKeys(template).forEach((key: string | undefined) => {
      template = template.replace(`{${key}}`, item[key as string] as string)
    })
    return template
  }
  return ''
}

/** @TODO потом выделю в отделбную функцуию разбор шаблона */
function valueTmpl(item: AutocompleteItem): string {
  if (item) {
    let template = props.titleTemplate
    templateKeys(template).forEach((key: string | undefined) => {
      template = template.replace(`{${key}}`, item[key as string] as string)
    })
    return template
  }
  return ''
}

function description(item: AutocompleteItem): string {
  let template = props.customDescriptionTemplate ? (item.descriptionTemplate as string || '') : props.descriptionTemplate
  const templateArray = template.split(' ')

  templateKeys(template).forEach((key: string, index: number) => {
    let result: any = item
    key.split('.').forEach((value) => {
      if (!result?.hasOwnProperty(value)) {
        result = '';
        return;
      }
      result = result[value]
    })

    template = result
      ? template.replace(`{${key}}`, result)
      : template.replace(templateArray[index], '')
  })
  return template || ''
}

onClickOutside(target, (e: Event) => {
  if (isOpen.value) {
    // Проверка для телефона
    if (e?.composedPath()?.find(target =>
      (target as Element)?.classList?.contains(`vz-new-autocomplete-list-${uid.value}`))
    ) {
      return
    }
    isOpen.value = false
  }
})

function onResize(): void {
  if ($breakpoints?.isXS400) {
    (inputControl2.value as any)?.$el.scrollIntoView({block: 'start', behavior: 'smooth'})
    return;
  }

  if (isOpen.value && $breakpoints?.isXS) {
    (inputControl.value as any)?.$el.scrollIntoView({block: 'start', behavior: 'smooth'})
  }
}

function isActive(item: AutocompleteItem, index: number): boolean {
  if (currentIndex.value === index) {
    return true
  }
  if (item[props.valueKey] === props.modelValue && !isSearch.value) {
    // todo: грязюка
    inputValue.value = title(item)
  }
  return item[props.valueKey] === props.modelValue
}

async function onSelect(): Promise<void> {
  const item = props.items[currentIndex.value]
  selectedItem = item
  await onChange(item)
}

function moveSelection(event?: KeyboardEvent): void {
  if (event?.code && ['ArrowDown', 'ArrowUp', 'PageUp', 'PageDown'].includes(event.code)) {
    event.preventDefault()
  }
  if (currentIndex.value < props.items.length - 1 && event?.code === 'ArrowDown') {
    currentIndex.value += 1
  }
  if (isOpen.value && event?.code === 'ArrowUp' && currentIndex.value > 0) {
    currentIndex.value -= 1
  }

  if (event?.code === 'PageUp') {
    currentIndex.value = Math.max(currentIndex.value - 4, 0)
  }

  if (event?.code === 'PageDown') {
    currentIndex.value = Math.min(currentIndex.value + 4, props.items.length - 1)
  }

  nextTick(() => {
    const divItems = list.value?.querySelectorAll('.vz-new-autocomplete-list-item')
    if (divItems?.length) {
      const divItem = divItems[currentIndex.value]
      if (divItem) {
        divItem.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }
  })
}

function templateKeys(template: string): (string)[] {
  const re = /{(.*?)}/g
  const res: (string)[] = []
  let current: RegExpExecArray | null = null
  // eslint-disable-next-line no-cond-assign
  while (current = re.exec(template)) {
    res.push(current.pop() as string)
  }
  return res.length > 0 ? res : []
}

function calculateListPosition(): void {
  if (!isOpen.value) {
    return;
  }
  if (!list.value || !vzInputControl.value) {
    console.error('list error', !!list.value, !!vzInputControl.value)
    return
  }

  if ($breakpoints?.isXS400) {
    list.value.classList.add('autocomplete-window-400px')
    return;
  }

  list.value.classList.remove('autocomplete-window-400px')

  const rect = list.value.getBoundingClientRect()
  const {top, left, height, width} = vzInputControl.value.getBoundingClientRect()

  const fullHeight = top + height + rect.height
  list.value.style.left = `${left}px`
  list.value.style.width = `${width}px`

  if (fullHeight > window.innerHeight) {
    list.value.style.top = `${top - rect.height}px` // `-${rect.height - labelRect.height - 6}px`
    list.value.style.boxShadow = '2px -2px 4px rgba(0, 0, 0, 0.15)'
  } else {
    list.value.style.top = `${top + height}px`
    list.value.style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.15)'
  }
}

function onClickESC(event: KeyboardEvent): void {
  if (event.code === 'Escape' && isOpen.value) {
    isOpen.value = false
    event.stopPropagation()
  }
  moveSelection(event)

  if (isOpen.value && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
    onSelect()
  }
}

function close() {
  isOpen.value = false
}

watch(listOpen, calculateListPosition, {flush: 'post'})
</script>
